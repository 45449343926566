"user strict"

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Highlight from "../components/common/highlight"
import Img from "gatsby-image"
import ScreenWidthMediaQueries from "../components/common/screen-widths"

import styled from "styled-components"

// fields: { slug: { eq: $slug } }
export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
const MetaSection = styled.div`
  color: #6d7d8b;
`

const MetaSeparator = styled.span`
  padding: 0 8px;
`

const BlogBody = styled.section`
  margin: 0 auto;
  max-width: 680px;
  padding: 0;
  padding: 15px 16px;
  @media ${ScreenWidthMediaQueries.tablet} {
    padding: 30px 0;
  }
`

const FeaturedImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const Post = ({ data }) => {
  const markdown = data.markdownRemark
  const {
    frontmatter: { date, title, featuredImage, slug },
  } = markdown

  const image = featuredImage.childImageSharp.fluid
  const categories = [] // post.categories.nodes.map(({ name }) => name)
  const dateFormat = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(new Date(date))
  return (
    <Layout>
      <SEO title={title} />
      <BlogBody>
        <h1>{title}</h1>
        <MetaSection>
          <time dateTime={dateFormat}>{dateFormat}</time>
          {categories.map(category => (
            <>
              <MetaSeparator />
              <Highlight size="small" key={category}>
                {category}
              </Highlight>
            </>
          ))}
        </MetaSection>
      </BlogBody>
      <FeaturedImageWrapper>
        <Img fluid={image} />
      </FeaturedImageWrapper>
      <BlogBody>
        <div dangerouslySetInnerHTML={{ __html: markdown.html }}></div>
      </BlogBody>
    </Layout>
  )
}

export default Post
